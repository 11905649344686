<template>
  <PagesNewHomeCommonContainer class="feature-page">
    <PagesNewHomeCommonAnimationFadeinContainer
      class="feature-title"
      :transform="30"
      :ani-show-top="200"
      :ani-hide-top="0"
    >
      <h2 class="title">
        {{ useTranslateI18n("newHome.feature.title") }}
        <CommonImage
          class="arrow"
          :src="getCloudAssets('/images/pages/newHome/home-feature-arrow.png')"
          alt=""
          :lazy="true"
          background="transparent"
        />
      </h2>
    </PagesNewHomeCommonAnimationFadeinContainer>
    <PagesNewHomeCommonAnimationBoxShowOneByOne
      v-for="(item, index) in featureData"
      :key="index"
      class="card-box"
      :transform="30"
      :ani-show-top="100"
      :ani-hide-top="0"
      @on-show="onCardShow(index)"
    >
      <PagesNewHomeFeatureCard ref="cardRef" :class="['card', 'delay-1', `card-${index + 1}`]" :data="item" />
    </PagesNewHomeCommonAnimationBoxShowOneByOne>
  </PagesNewHomeCommonContainer>
</template>

<script lang="ts" setup>
import { getFeatureData } from "~/constant/pages/newHome"

const featureData = getFeatureData()

const cardRef = ref()

const videPlay = (index: number) => {
  cardRef.value[index].video.video.currentTime = 0
  cardRef.value[index].video.play()
  cardRef.value[index].showReplayBtn = false
}

const onCardShow = async (index: number) => {
  // 兼容处理返回页面，视频无法播放的问题
  cardRef.value[index].video.video.addEventListener("loadedmetadata", () => {
    videPlay(index)
  })
  // 正常播放
  videPlay(index)
}

onMounted(() => {})
</script>

<style lang="scss" scoped src="./index.scss" />
