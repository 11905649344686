export function useTouch(el) {
  const direction = ref(null)
  let startX, startY

  el.addEventListener(
    "touchstart",
    function (event) {
      event.preventDefault() // 防止滚动屏幕
      const touch = event.touches[0]
      startX = touch.pageX
      startY = touch.pageY
    },
    { passive: true }
  )

  const during = 50

  el.addEventListener(
    "touchend",
    function (event) {
      const touch = event.changedTouches[0]
      const currentX = touch.pageX
      const currentY = touch.pageY

      const deltaX = currentX - startX
      const deltaY = currentY - startY

      if (Math.abs(deltaX) < during && Math.abs(deltaY) < during) {
        return
      }

      // 判断滑动方向
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        // 水平方向滑动
        if (deltaX > 0) {
          direction.value = "right"
        } else {
          direction.value = "left"
        }
      } else {
        // 垂直方向滑动
        if (deltaY > 0) {
          direction.value = "bottom"
        } else {
          direction.value = "top"
        }
      }
      setTimeout(() => {
        direction.value = null
      })
    },
    { passive: true }
  )

  return {
    direction
  }
}
