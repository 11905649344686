<template>
  <div ref="pageRef" class="translate-animation-box">
    <div class="content-box" :style="contentBoxStyle">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { StyleValue } from "vue"

const contentBoxStyle = ref<StyleValue>()

const pageRef = ref()

// 动画前后额外监听高度
const extraHeight = 200

const setAnimation = (y: number) => {
  const boxTop = pageRef.value?.offsetTop
  const boxHeight = pageRef.value?.offsetHeight > window.innerHeight ? window.innerHeight : pageRef.value?.offsetHeight
  const startTranslate = -0.3
  const endTranslate = 0

  if (y < boxTop - window.innerHeight - extraHeight) {
    return
  }
  let progress = (y - (boxTop - window.innerHeight)) / boxHeight
  if (progress < 0) {
    progress = 0
  }
  if (progress > 1) {
    progress = 1
  }
  contentBoxStyle.value = {
    transform: `translate(0 ,${(1 - progress) * (startTranslate - endTranslate) * boxHeight}px)`,
    willChange: "transform",
    transformStyle: "preserve-3d"
  }
}

const { y } = useWindowScroll()

watch(
  () => y.value,
  (value) => {
    setAnimation(value)
  }
)
</script>

<style lang="scss" src="./index.scss" scoped />
