<template>
  <div class="simplify-life-page">
    <PagesNewHomeCommonContainer :id="SIMOLIFY_LIFE_ID" class="page-area">
      <PagesNewHomeCommonAnimationBoxShowOneByOne
        :transform="30"
        :ani-show-top="300"
        :ani-hide-top="0"
        @on-show="showVideo"
      >
        <div class="title-box delay-1">
          <div :class="['title-hide-box']">
            <h2 class="title">{{ useTranslateI18n("newHome.simplifyLife.title") }}</h2>
            <h3 class="sub-title">{{ useTranslateI18n("newHome.simplifyLife.subTitle") }}</h3>
          </div>
        </div>
        <div class="center-area delay-2">
          <div class="video-area">
            <div class="tab-area">
              <div
                v-for="(item, index) in SimplifyLifeData"
                :key="item.tabName"
                :class="['tab-item', { 'active-tab': index === videoIndex }]"
                :style="getTabStyle(index)"
                @click="tabClick(index)"
              >
                <div class="index-box">
                  <component :is="item.icon" class="tab-icon" />
                  <span class="tab-name">{{ item.tabName }}</span>
                </div>
              </div>
            </div>
            <div class="video-desc-area">
              <div
                v-for="(item, index) in SimplifyLifeData"
                :key="index"
                :class="['ani-area', { 'active-text': videoIndex === index }]"
              >
                <p class="desc">{{ item.desc }}</p>
                <div class="link-btn" :style="getAStyle" @click="toDownload">
                  <span class="btn-text">{{ useTranslateI18n("newHome.simplifyLife.btnText") }}</span>
                  <Arrow class="btn-icon" />
                </div>
              </div>
            </div>
            <div class="swiper-area">
              <div ref="touchRef" class="video-area">
                <CommonVideo
                  v-for="(item, index) in SimplifyLifeData"
                  :key="item.tabName"
                  ref="videoRef"
                  v-video-lazy="{
                    src: item.video,
                    poster: item.poster
                  }"
                  :class="['video', { active: videoIndex === index }]"
                  :autoplay="index === 0 ? true : false"
                  muted
                  :loop="false"
                  :alt="item.alt"
                  @ended="playEnd(index)"
                />
                <CommonVideo
                  v-video-lazy="{
                    src: SimplifyLifeData[0].video,
                    poster: SimplifyLifeData[0].poster
                  }"
                  :class="['video-reserve']"
                  :autoplay="false"
                  muted
                  :loop="false"
                  :alt="SimplifyLifeData[0].alt"
                />
              </div>
              <div :class="['btn', 'left-btn', { hide: hideLeftBtn }]" @click="toPreVideo"><Arrow /></div>
              <div :class="['btn', 'right-btn', { hide: hideRightBtn }]" @click="toNextVideo"><Arrow /></div>
              <div class="indicator-dots-area">
                <div
                  v-for="(item, index) in SimplifyLifeData"
                  :key="index"
                  :class="['dots', { 'dots-activity': videoIndex === index }]"
                />
              </div>
            </div>
          </div>
        </div>
      </PagesNewHomeCommonAnimationBoxShowOneByOne>
    </PagesNewHomeCommonContainer>
  </div>
</template>

<script lang="ts" setup>
import { Breakpoints, SIMOLIFY_LIFE_ID, getSimplifyLifeData } from "~/constant/pages/newHome"
import Arrow from "@/assets/images/pages/newHome/home-simplify-life-arrow.svg"
import { DOWNLOAD_HELP_PAGE_PATH, DOWNLOAD_PAGE_PATH } from "~/constant/route"
import { useUser } from "~/store/user"

const SimplifyLifeData = getSimplifyLifeData()

const videoIndex = ref(0)
const oldIndex = ref<number>(0)

const getTabStyle = computed(() => {
  return (index) => {
    if (index === videoIndex.value) {
      return {
        color: SimplifyLifeData[index].activityColor,
        "--background": SimplifyLifeData[index].activityBackground
      }
    }
    return {}
  }
})

const getAStyle = computed(() => {
  return {
    color: SimplifyLifeData[videoIndex.value].activityColor
  }
})

const { _pointNewHomeSimplifyLifeClick } = useNewHomeTsSdk()

const toDownload = () => {
  _pointNewHomeSimplifyLifeClick()
  // const { isMobile } = useDevice()
  // if (isMobile.value) {
  //   const use_user = useUser()
  //   const useEmail = use_user.userInfo?.email
  //   navigateTo({
  //     path: unref(DOWNLOAD_HELP_PAGE_PATH),
  //     query: useEmail
  //       ? {
  //           e: useEmail
  //         }
  //       : {}
  //   })
  //   return
  // }
  navigateTo(DOWNLOAD_PAGE_PATH.value)
}

const videoRef = ref()

const playVideo = (index: number) => {
  if (videoIndex.value == index) {
    return
  }
  videoRef.value[index].video.currentTime = 0
  videoRef.value[videoIndex.value].pause()
  oldIndex.value = videoIndex.value
  videoIndex.value = index
  videoRef.value[videoIndex.value].play()
  videoRef.value[videoIndex.value].autoPlayOnMobile()
}

const hideLeftBtn = computed(() => {
  return videoIndex.value === 0
})

const hideRightBtn = computed(() => {
  return videoIndex.value === SimplifyLifeData.length - 1
})

const toPreVideo = () => {
  if (hideLeftBtn.value) {
    return
  }
  playVideo((videoIndex.value - 1 + SimplifyLifeData.length) % SimplifyLifeData.length)
}

const toNextVideo = () => {
  if (hideRightBtn.value) {
    return
  }
  playVideo((videoIndex.value + 1) % SimplifyLifeData.length)
}

const tabClick = (index: number) => {
  playVideo(index)
  videoIndex.value = index
}

const playEnd = (index: number) => {
  if (videoIndex.value === index) {
    playVideo((videoIndex.value + 1) % SimplifyLifeData.length)
  }
}

const showVideo = () => {
  const index = 0
  videoRef.value[index].video.currentTime = 0
  videoRef.value[videoIndex.value].pause()
  oldIndex.value = videoIndex.value
  videoIndex.value = index
  videoRef.value[videoIndex.value].play()
  videoRef.value[videoIndex.value].autoPlayOnMobile()
}

const touchRef = ref()

const setTouch = () => {
  const { direction } = useTouch(touchRef.value)
  watch(
    () => direction.value,
    () => {
      if (direction.value) {
        switch (direction.value) {
          case "left":
            toNextVideo()
            break
          case "right":
            toPreVideo()
            break
        }
      }
    }
  )
}

onMounted(() => {
  setTouch()
})
</script>

<style lang="scss" src="./index.scss" scoped />
