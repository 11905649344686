<template>
  <div ref="pageRef" class="sticky-animation-box">
    <div class="sticky-box">
      <div class="filter-box">
        <div ref="centerBox" class="conetnt-box">
          <slot />
        </div>
      </div>
      <div class="cover-box" :style="contentBoxStyle" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { StyleValue } from "vue"
import { NAV_ID } from "~/constant/navbar"
import { Breakpoints } from "~/constant/pages/newHome"
import { ResizeSensor } from "css-element-queries"

interface propsInterface {
  // 动画开始
  aniStart?: number
  // 动画结束
  aniEnd?: number
  // 最低亮度
  minBrightness?: number
  // 高度
  top?: number
}

const props = withDefaults(defineProps<propsInterface>(), {
  aniStart: 0.4,
  aniEnd: 0.8,
  minBrightness: 0.4,
  top: null
})

const contentBoxStyle = ref<StyleValue>()

const pageRef = ref()

// 动画前后额外监听高度
const extraHeight = 200

const setAnimation = (y: number) => {
  const boxTop = pageRef.value?.offsetTop
  const boxHeight = pageRef.value?.offsetHeight
  if (y < boxTop - extraHeight || y > boxTop + boxHeight + extraHeight) {
    return
  }
  let progress = (y - boxTop) / boxHeight
  if (progress < 0) {
    progress = 0
  }
  if (progress > 1) {
    progress = 1
  }
  if (progress <= props.aniStart) {
    contentBoxStyle.value = {
      "backdrop-filter": `brightness(${1})`
    }
    return
  }
  if (progress >= props.aniEnd) {
    contentBoxStyle.value = {
      "backdrop-filter": `brightness(${props.minBrightness})`
    }
    return
  }
  contentBoxStyle.value = {
    "backdrop-filter": `brightness(${
      (1 - (progress - props.aniStart) / (props.aniEnd - props.aniStart)) * (1 - props.minBrightness) +
      props.minBrightness
    })`
  }
}

const { y } = useWindowScroll()

watch(
  () => y.value,
  (value) => {
    if (breakPointList.$600.value) {
      setAnimation(value)
    }
  }
)

const stickyTop = ref(0)
const minHeight = ref(0)
const centerBox = ref()

const getStickyTop = () => {
  const nav = document.getElementById(NAV_ID) as HTMLElement
  const navHeight = nav?.offsetHeight || 0
  stickyTop.value =
    props.top === null
      ? 0 -
        centerBox.value?.offsetTop +
        navHeight +
        (window.innerHeight - centerBox.value?.offsetHeight - navHeight) / 2
      : props.top
}

const breakPointList = useBreakpoints(Breakpoints) as any

const getResizeSensor = () => {
  nextTick(async () => {
    await nextTick()
    if (breakPointList.$1920.value) {
      minHeight.value = 1080
      getStickyTop()
    } else if (breakPointList.$1400.value) {
      minHeight.value = 1080
      getStickyTop()
    } else if (breakPointList.$900.value) {
      getStickyTop()
      minHeight.value = 616
    } else if (breakPointList.$600.value) {
      minHeight.value = 0
      getStickyTop()
    } else {
      minHeight.value = 0
    }
  })
}

onMounted(() => {
  new ResizeSensor(pageRef.value, () => {
    getResizeSensor()
  })
})
</script>

<style lang="scss" src="./index.scss" scoped />
<style lang="scss" scoped>
.sticky-animation-box {
  --height: 100vh;
  --min-height: calc(v-bind(minHeight) * 1px);
  --sticky-top: calc(v-bind(stickyTop) * 1px);
}
</style>
