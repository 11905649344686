<template>
  <div class="comming-activity-card">
    <div class="info-area">
      <h3 class="name">{{ props.data.name }}</h3>
      <span class="area">{{ props.data.area }}</span>
      <span class="date">{{ props.data.date }}</span>
    </div>
    <CommonImage
      class="img"
      :src="props.data.img"
      :lazy="true"
      :alt="props.data.alt"
      background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
    />
  </div>
</template>

<script lang="ts" setup>
import { CommingActivityType } from "~/types/pages/newHome"

interface IProps {
  data: CommingActivityType
}

const props = withDefaults(defineProps<IProps>(), {
  data: () => ({
    name: "",
    area: "",
    date: "",
    img: "",
    alt: ""
  })
})

const {} = toRefs(props)

onMounted(() => {})
</script>

<style lang="scss" scoped src="./index.scss" />
