<template>
  <div class="feature-card">
    <div class="content-area">
      <h2 class="title" v-html="props.data.title" />
      <p class="desc">{{ props.data.desc }}</p>
      <div class="btn-area">
        <PagesNewHomeCommonToDownloadBtn class="to-download" @click="toDownload" />
        <span class="learn-more" v-if="props.data.path" @click="toPath">{{
          useTranslateI18n("newHome.feature.helpText")
        }}</span>
      </div>
    </div>
    <div class="video-area">
      <CommonVideo
        ref="videoRef"
        v-video-lazy="{
          src: props.data.video,
          poster: props.data.poster
        }"
        class="video"
        :autoplay="false"
        muted
        :loop="false"
        :alt="props.data.alt"
        @ended="playEnd"
      />
      <button :class="['replay-btn', { 'show-play-btn': showReplayBtn }]" @click="playVideo">
        <CommonImage
          class="play-icon"
          :src="getCloudAssets('/images/pages/newHome/home-video-replay-icon.png')"
          :lazy="true"
          background="transparent"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FeatureType } from "~/types/pages/newHome"

interface propsInterface {
  data: FeatureType
}

const props = withDefaults(defineProps<propsInterface>(), {
  data: () => {
    return {
      title: "",
      desc: "",
      video: "",
      alt: "",
      path: undefined,
      poster: ""
    }
  }
})

const showReplayBtn = ref(false)

const videoRef = ref()

const playVideo = () => {
  showReplayBtn.value = false
  videoRef.value.play()
}

const playEnd = () => {
  showReplayBtn.value = true
}

const toPath = () => {
  navigateTo(props.data.path)
}

const { _pointNewHomeFeatureClick } = useNewHomeTsSdk()

const toDownload = () => {
  _pointNewHomeFeatureClick()
}

onMounted(() => {})

defineExpose({
  video: videoRef,
  showReplayBtn: showReplayBtn
})
</script>

<style lang="scss" scoped src="./index.scss" />
