<template>
  <button class="btn" @click="toDownload">
    <slot>{{ useTranslateI18n("newHome.hero.btn") }}</slot>
    <Arrow class="icon" />
  </button>
</template>

<script lang="ts" setup>
import Arrow from "@/assets/images/pages/newHome/home-hero-btn-arrow.svg"
import { DOWNLOAD_PAGE_PATH } from "~/constant/route"

const toDownload = () => {
  navigateTo(unref(DOWNLOAD_PAGE_PATH))
}
</script>

<style scoped lang="scss" src="./index.scss" />
