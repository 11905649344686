<template>
  <div class="ending-page">
    <div class="bg-area">
      <CommonImage class="bg" :src="getCloudAssets('/images/common/ev-bg.png')" :lazy="true" background="transparent" />
    </div>
    <div class="content-area">
      <PagesNewHomeCommonAnimationBoxShowOneByOne :transform="30" :ani-show-top="200" :ani-hide-top="0">
        <h2 class="title delay-1">{{ useTranslateI18n("newHome.ending.title") }}</h2>
        <div class="bottom-area delay-2">
          <div class="desc-area">
            <p class="desc">
              {{ useTranslateI18n("newHome.ending.desc1") }}
            </p>
            <p class="desc">
              {{ useTranslateI18n("newHome.ending.desc2") }}
            </p>
          </div>
          <button class="btn" @click="toDownload">{{ useTranslateI18n("newHome.ending.btn") }}</button>
        </div>
      </PagesNewHomeCommonAnimationBoxShowOneByOne>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DOWNLOAD_PAGE_PATH } from "~/constant/route"

const { _pointNewHomeEndingClick } = useNewHomeTsSdk()

const toDownload = () => {
  _pointNewHomeEndingClick()
  // const { isMobile } = useDevice()
  // if (isMobile.value) {
  //   const use_user = useUser()
  //   const useEmail = use_user.userInfo?.email
  //   navigateTo({
  //     path: unref(DOWNLOAD_HELP_PAGE_PATH),
  //     query: useEmail
  //       ? {
  //           e: useEmail
  //         }
  //       : {}
  //   })
  //   return
  // }
  navigateTo(DOWNLOAD_PAGE_PATH.value)
}
</script>

<style lang="scss" scoped src="./index.scss" />
