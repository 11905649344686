<template>
  <div :id="HERO_ID" class="hero-page">
    <PagesNewHomeCommonContainer :class="['hero-container', { 'show-ani': showAni }]">
      <div class="hero-content">
        <div class="bg-area"></div>
        <div class="title-area">
          <h1 class="title">
            <!-- used to reduce CLS -->
            <div class="cls-box">
              <span class="title-text">{{ useTranslateI18n("newHome.hero.title1") }}</span
              ><span>
                <div class="title-img-1-ani">
                  <CommonImage
                    class="title-img-1"
                    :src="getCloudAssets('/images/pages/newHome/home-hero-title-img-1.png')"
                    background="transparent"
                  />
                </div>
              </span>
            </div>
            <span class="title-text-2">{{ useTranslateI18n("newHome.hero.title2") }}</span>
            <img
              v-if="showIcon"
              class="title-img-2"
              :src="getCloudAssets('/images/pages/newHome/home-hero-title-img-2.png')"
              alt=""
            />
          </h1>
          <PagesNewHomeCommonToDownloadBtn :id="HERO_FREE_BTN" class="to-download-btn" @click="toDownload" />
        </div>
        <div class="interval" />
        <div class="video-area">
          <div class="video-bg">
            <CommonImage
              class="bg-1"
              :src="getCloudAssets('/images/pages/newHome/home-hero-bg-1.png')"
              background="transparent"
            />
            <div class="bg-2-area">
              <CommonImage
                class="bg-2"
                :src="getCloudAssets('/images/pages/newHome/home-hero-bg-2.png')"
                background="transparent"
              />
            </div>
            <div class="bg-3">
              <div class="scale-box">{{ useTranslateI18n("newHome.hero.bgText") }}</div>
            </div>
            <div class="bg-4">
              <div class="scale-box">
                <div class="bg-4-text-1">{{ useTranslateI18n("newHome.hero.bgText2") }}</div>
                <div class="bg-4-text-2">{{ useTranslateI18n("newHome.hero.bgText3") }}</div>
              </div>
            </div>
          </div>
          <CommonVideo
            :src="getCloudAssets('/images/pages/newHome/home-hero-video.mp4')"
            :poster="getCloudAssets('/images/pages/newHome/home-hero-video-poster.webp')"
            class="video"
            autoplay
            muted
            loop
            :alt="useTranslateI18n('newHome.hero.videoAlt')"
          />
        </div>
      </div>
    </PagesNewHomeCommonContainer>
  </div>
</template>

<script lang="ts" setup>
import { HERO_FREE_BTN, HERO_ID } from "~/constant/pages/home"
const showAni = ref(false)

const { _pointNewHomeHeroClick } = useNewHomeTsSdk()

const toDownload = () => {
  _pointNewHomeHeroClick()
}

// 避免由于文字变化导致的cls(Cumulative Layout Shift)
const showIcon = ref(false)

onMounted(async () => {
  // await nextTick()
  showIcon.value = true
  // showAni.value = true
})
</script>

<style lang="scss" scoped src="./index.scss" />
