<template>
  <div ref="boxRef" class="box-background">
    <div v-show="showContent" v-if="showContentIf" class="hide-box">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  aniShowTop: {
    type: Number,
    default: 50
  },
  aniHideTop: {
    type: Number,
    default: 200
  },
  useIf: {
    type: Boolean,
    default: false
  }
})

const showContent = ref<boolean>(false)
const showContentIf = ref<boolean>(false)

const boxRef = ref()

onMounted(() => {
  handleScroll()
  nextTick(() => {
    window.addEventListener("scroll", handleScroll, true)
  })
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll, true)
})

const handleScroll = () => {
  nextTick(() => {
    const domRef: any = boxRef.value
    if (!domRef) {
      return
    }
    const position = domRef.getBoundingClientRect().top
    const clientHeight = document.documentElement.clientHeight
    if (props.useIf) {
      showContent.value = true
      if (showContentIf.value) {
        // 效果优势
        showContentIf.value = position + props.aniHideTop <= clientHeight
      } else {
        showContentIf.value = position + props.aniShowTop <= clientHeight
      }
    } else {
      showContentIf.value = true
      if (showContent.value) {
        // 效果优势
        showContent.value = position + props.aniHideTop <= clientHeight
      } else {
        showContent.value = position + props.aniShowTop <= clientHeight
      }
    }
  })
}
</script>

<style lang="scss" scoped>
.hide-box {
  animation: show-box 0.3s;
}
@keyframes show-box {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
