<template>
  <div :id="ADVANTAGE_ID" ref="pageRef" class="advantage-page">
    <div class="bg-area">
      <div class="bg-sticky-area">
        <CommonImage
          class="bg"
          :src="getCloudAssets('/images/pages/newHome/home-advantage-bg.png')"
          :lazy="true"
          background="transparent"
        />
      </div>
    </div>
    <PagesNewHomeCommonContainer ref="pageAreaRef" class="advantage-content">
      <div class="sticky-box">
        <PagesNewHomeCommonAnimationBoxShowOneByOne
          :transform="30"
          :ani-show-top="200"
          :ani-hide-top="0"
          :delay="0.3"
          @on-show="onShowVideo"
        >
          <div ref="titleBoxRef" :class="['title-box', 'delay-1']">
            <div :class="['title-hide-box', { 'title-hide': titleHide }]">
              <h2 class="title">{{ useTranslateI18n("newHome.advantage.title") }}</h2>
              <h3 class="desc">{{ useTranslateI18n("newHome.advantage.desc") }}</h3>
            </div>
          </div>
          <div ref="centerBox" class="center-area">
            <div class="fade-area delay-2">
              <div :class="['tab', 'tab-left', { 'show-left': showLeft }]">
                <PagesNewHomeAdvantageTabChoose
                  ref="leftTabChooseRef"
                  class="tab-choose"
                  :index="getIndex1"
                  :tab-data="getFirstData"
                  :process="getProcess1"
                  @tab-change="tabChange1"
                >
                  <div v-if="!showCenterVideo" class="tab-video-area">
                    <CommonVideo
                      v-for="(item, index) in AdvantageData.slice(0, 2)"
                      ref="videoRef"
                      :key="index"
                      v-video-lazy="{
                        src: item.video,
                        poster: item.poster
                      }"
                      :loop="false"
                      :autoplay="false"
                      :class="['video', { active: pageIndex1 === index }]"
                      :alt="item.alt"
                      @ended="pagePlayEnd1(index)"
                      @timeupdate="videoPlaying1(index)"
                    />
                  </div>
                </PagesNewHomeAdvantageTabChoose>
              </div>

              <div v-if="showCenterVideo" class="video-area">
                <CommonVideo
                  v-for="(item, index) in AdvantageData"
                  ref="videoRef"
                  :key="index"
                  v-video-lazy="{
                    src: item.video,
                    poster: item.poster
                  }"
                  :loop="false"
                  :autoplay="videoIndex === index"
                  :class="['video', { active: videoIndex === index }]"
                  :alt="item.alt"
                  @ended="playEnd(index)"
                  @timeupdate="videoPlaying(index)"
                />
                <!-- <button :class="['replay-btn', { 'show-play-btn': videoIndex === playEndIndex }]" @click="playVideo">
                  <CommonImage
                    class="play-icon"
                    :src="getCloudAssets('/images/pages/newHome/home-video-replay-icon.png')"
                  />
                </button> -->
              </div>
              <div :class="['tab', 'tab-right', { 'show-right': showRight }]">
                <PagesNewHomeAdvantageTabChoose
                  ref="rightTabChooseRef"
                  class="tab-choose"
                  :index="getIndex2"
                  :tab-data="getSecondData"
                  :process="getProcess2"
                  @tab-change="tabChange2"
                >
                  <div v-if="!showCenterVideo" class="tab-video-area">
                    <CommonVideo
                      v-for="(item, index) in AdvantageData.slice(2, 4)"
                      ref="videoRef"
                      :key="index"
                      v-video-lazy="{
                        src: item.video,
                        poster: item.poster
                      }"
                      :loop="false"
                      :autoplay="false"
                      :class="['video', { active: pageIndex2 === index }]"
                      :alt="item.alt"
                      @ended="pagePlayEnd2(index)"
                      @timeupdate="videoPlaying2(index)"
                    />
                    <!-- <button
                      :class="['replay-btn', { 'show-play-btn': pageIndex2 === pageEndIndex2 }]"
                      @click="playTabVideo({ pageIndexParam2: pageIndex2 })"
                    >
                      <CommonImage
                        class="play-icon"
                        :src="getCloudAssets('/images/pages/newHome/home-video-replay-icon.png')"
                      />
                    </button> -->
                  </div>
                </PagesNewHomeAdvantageTabChoose>
              </div>
            </div>
          </div>
        </PagesNewHomeCommonAnimationBoxShowOneByOne>
      </div>
    </PagesNewHomeCommonContainer>
  </div>
</template>

<script lang="ts" setup>
import { ResizeSensor } from "css-element-queries"
import { EmitName } from "~/constant/eventBus"
import { NAV_ID } from "~/constant/navbar"
import { ADVANTAGE_ID, Breakpoints, getAdvantageData } from "~/constant/pages/newHome"

const AdvantageData = getAdvantageData()

const getFirstData = computed(() => {
  return AdvantageData.slice(0, 2)
})

const getSecondData = computed(() => {
  return AdvantageData.slice(2, 4)
})

const pageIndex1 = ref(0)
const pageIndex2 = ref(0)
const videoIndex = ref(0)
const pageEndIndex1 = ref(-1)
const pageEndIndex2 = ref(-1)
const playEndIndex = ref(-1)

const pageOldIndex = ref(0)
const pageOldIndex1 = ref(0)
const pageOldIndex2 = ref(0)

const minHeight = ref(0)
const stickyTop = ref(0)
const boxMultiple = 4.7
const pageAreaRef = ref()
const centerBox = ref()
let pageHeight = 0

const leftTabChooseRef = ref()
const rightTabChooseRef = ref()

const showLeft = computed(() => {
  return videoIndex.value < 2
})

const showRight = computed(() => {
  return videoIndex.value >= 2
})

const videoRef = ref()

const playVideo = () => {
  if (videoIndex.value < 0 || !videoRef.value[videoIndex.value]) {
    return
  }
  videoRef.value[pageOldIndex.value].pause()
  processPage.value = 0
  videoRef.value[videoIndex.value].video.currentTime = 0
  videoRef.value[videoIndex.value].play()
  videoRef.value[videoIndex.value].autoPlayOnMobile()
  pageOldIndex.value = videoIndex.value
  playEndIndex.value = -1
}

const playTabVideo = ({ pageIndexParam1, pageIndexParam2 }: { pageIndexParam1?: number; pageIndexParam2?: number }) => {
  if (videoIndex.value < 0 || !videoRef.value[videoIndex.value]) {
    return
  }
  let currentIndex
  if (pageIndexParam1 !== undefined) {
    processPage1.value = 0
    currentIndex = pageIndexParam1
    videoRef.value[pageOldIndex1.value].pause()
    pageEndIndex1.value = -1
  }
  if (pageIndexParam2 !== undefined) {
    processPage2.value = 0
    currentIndex = pageIndexParam2 + 2
    videoRef.value[pageOldIndex2.value + 2].pause()
    pageEndIndex2.value = -1
  }
  videoRef.value[currentIndex].video.currentTime = 0
  videoRef.value[currentIndex].play()
  videoRef.value[currentIndex].autoPlayOnMobile()

  if (pageIndexParam1 !== undefined) {
    pageOldIndex1.value = pageIndexParam1
  }
  if (pageIndexParam2 !== undefined) {
    pageOldIndex2.value = pageIndexParam2
  }
}

const tabChange1 = (index: number) => {
  if (pageIndex1.value === index) {
    return
  }
  videoIndex.value = index
  pageIndex1.value = index
  leftTabChooseRef.value?.countToRef?.reset()
  if (showCenterVideo.value) {
    playVideo()
  } else {
    playTabVideo({ pageIndexParam1: pageIndex1.value })
    return
  }
  window.scrollTo({
    top: pageRef.value.offsetTop + videoIndex.value * pageHeight + 0.5 * pageHeight
  })
}

const tabChange2 = (index: number) => {
  if (pageIndex2.value === index) {
    return
  }
  videoIndex.value = index + 2
  pageIndex2.value = index
  rightTabChooseRef.value?.countToRef?.reset()
  if (showCenterVideo.value) {
    playVideo()
  } else {
    playTabVideo({ pageIndexParam2: pageIndex2.value })
    return
  }
  window.scrollTo({
    top: pageRef.value.offsetTop + videoIndex.value * pageHeight + 0.5 * pageHeight
  })
}

const getIndex1 = computed(() => {
  if (showCenterVideo.value) {
    return videoIndex.value
  } else {
    return pageIndex1.value
  }
})

const getIndex2 = computed(() => {
  if (showCenterVideo.value) {
    return videoIndex.value - 2
  } else {
    return pageIndex2.value
  }
})

const pageRef = ref()

const initPageData = () => {
  pageHeight = pageRef.value?.offsetHeight / boxMultiple
}

// 动画前后额外监听高度
const extraHeight = 500

// 设置标题隐藏
const titleHide = ref(false)

const titleBoxRef = ref()

const setAnimation = (y: number) => {
  const boxTop = pageRef.value?.offsetTop
  const boxHeight = pageRef.value?.offsetHeight
  if (y < boxTop - extraHeight || y > boxTop + boxHeight + extraHeight) {
    return
  }

  // 标题处理
  const nav = document.getElementById(NAV_ID)
  const top = titleBoxRef.value.getBoundingClientRect().top
  titleHide.value = top < nav.offsetHeight

  const index = Math.floor((y - pageRef.value?.offsetTop) / pageHeight)

  if (index === videoIndex.value) {
    return
  }
  if (index < 0 || index >= AdvantageData.length) {
    return
  }
  videoIndex.value = index
  if (index < 2) {
    pageIndex1.value = index
    leftTabChooseRef.value?.countToRef?.reset()
  } else {
    pageIndex2.value = index - 2
    rightTabChooseRef.value?.countToRef?.reset()
  }
  playVideo()
}

const { y } = useWindowScroll()

watch(
  () => y.value,
  (value) => {
    if (showCenterVideo.value) {
      setAnimation(value)
    }
  }
)

const getStickyTop = () => {
  const nav = document.getElementById(NAV_ID) as HTMLElement
  const navHeight = nav?.offsetHeight || 0
  stickyTop.value =
    0 - centerBox.value?.offsetTop + navHeight + (window.innerHeight - centerBox.value?.offsetHeight - navHeight) / 2
}

const playEnd = (index: number) => {
  playEndIndex.value = index
}

const pagePlayEnd1 = (index: number) => {
  if (index === pageIndex1.value) {
    pageEndIndex1.value = index
  }
}

const pagePlayEnd2 = (index: number) => {
  if (index === pageIndex2.value) {
    pageEndIndex2.value = index
  }
}

const onShowVideo = () => {
  if (!showCenterVideo.value) {
    playTabVideo({ pageIndexParam1: pageIndex1.value })
    playTabVideo({ pageIndexParam2: pageIndex2.value })
  } else {
    playVideo()
  }
}

const processPage = ref(0)
const processPage1 = ref(0)
const processPage2 = ref(0)

const videoPlaying = (index: number) => {
  if (videoIndex.value !== index) {
    return
  }
  processPage.value = videoRef.value[index]?.video.currentTime / videoRef.value[index]?.video.duration
}

const videoPlaying1 = (index: number) => {
  processPage1.value = videoRef.value[index]?.video.currentTime / videoRef.value[index]?.video.duration
}

const videoPlaying2 = (index: number) => {
  processPage2.value = videoRef.value[index + 2]?.video.currentTime / videoRef.value[index + 2]?.video.duration
}

const getProcess1 = computed(() => {
  if (showCenterVideo.value) {
    return processPage.value
  }
  return processPage1.value
})

const getProcess2 = computed(() => {
  if (showCenterVideo.value) {
    return processPage.value
  }
  return processPage2.value
})

const breakPointList = useBreakpoints(Breakpoints) as any

const showCenterVideo = ref(false)

const getResizeSensor = () => {
  nextTick(async () => {
    await nextTick()
    if (breakPointList.$1920.value) {
      minHeight.value = 1080
      showCenterVideo.value = true
    } else if (breakPointList.$1400.value) {
      minHeight.value = 1080
      showCenterVideo.value = true
    } else if (breakPointList.$900.value) {
      minHeight.value = 616
      showCenterVideo.value = true
    } else if (breakPointList.$600.value) {
      minHeight.value = 0
      showCenterVideo.value = false
    } else {
      minHeight.value = 0
      showCenterVideo.value = false
    }
    await nextTick()
    initPageData()
    await delay(500)
    getStickyTop()
  })
}

onMounted(() => {
  new ResizeSensor(pageRef.value, () => {
    getResizeSensor()
  })
})
</script>

<style lang="scss" src="./index.scss" scoped />
<style lang="scss" scoped>
.advantage-page {
  --height: 100vh;
  --min-height: calc(v-bind(minHeight) * 1px);
  --sticky-top: calc(v-bind(stickyTop) * 1px);
  --box-multiple: v-bind(boxMultiple);
}
</style>
