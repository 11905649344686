<template>
  <PagesNewHomeCommonContainer :id="SCENE_ID" class="scene-page">
    <div class="scene-container">
      <PagesNewHomeCommonAnimationBoxShowOneByOne
        class="show-box"
        :transform="30"
        :ani-show-top="100"
        :ani-hide-top="0"
      >
        <div class="title-box delay-1">
          <h2 class="title">{{ useTranslateI18n("newHome.scene.title") }}</h2>
          <h3 class="desc">{{ useTranslateI18n("newHome.scene.desc") }}</h3>
        </div>

        <div class="box delay-2">
          <PagesNewHomeSceneTabChoose v-model="tabIndex" class="tab" :tab-data="sceneData" @tab-change="tabChange" />
          <div ref="compareAreaRef" class="compare-area">
            <div class="hover-area">
              <div
                v-for="(item, index) in sceneData"
                :key="index"
                :class="['hover-item', { 'show-effect': tabIndex === index }]"
              >
                <div
                  v-for="(e, i) in item.effectList"
                  ref="hoverAreaRef"
                  :key="i"
                  :class="[
                    'common-effect-item',
                    `effect-item-${i + 1}`,
                    { 'effect-active': showCompareImg(index, i) },
                    {
                      'effect-hide': getHideClass(index, i)
                    }
                  ]"
                  :style="getItemStyle(index, i)"
                >
                  <CommonImage
                    class="img"
                    :src="e.img"
                    :lazy="true"
                    :alt="item.alt"
                    background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
                  />
                  <div class="card-info">
                    <div class="card-top">
                      <div class="card-title">
                        {{ e.desc }}
                      </div>
                      <div class="icon-box" v-if="i === 0">
                        <CommonImage
                          class="card-icon"
                          :src="getCloudAssets('/images/pages/newHome/home-scene-hover-hand.png')"
                          :lazy="true"
                          background="transparent"
                        />
                      </div>
                    </div>
                    <div class="card-bottom">
                      <div class="card-title">
                        {{ e.title }}
                        <div class="card-ai">AI</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="img-area">
              <div
                v-for="(item, index) in sceneData"
                :key="index"
                :class="['effect-item', { active: tabIndex === index }]"
              >
                <CommonImage
                  class="img"
                  :src="item.oriImg"
                  :lazy="true"
                  :alt="item.alt"
                  background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
                />
                <div class="effect-img-area">
                  <CommonImage
                    v-for="(e, i) in item.effectList"
                    :key="i"
                    :class="['effect-img', `effect-img-${index}`, { 'effect-img-active': showCompareImg(index, i) }]"
                    :src="e.img"
                    :alt="item.alt"
                    background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
                    :lazy="true"
                  />
                </div>
              </div>
            </div>
            <CommonImage
              class="img-reserve"
              :src="sceneData[0].oriImg"
              :lazy="true"
              :alt="sceneData[0].alt"
              background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
            />
          </div>
        </div>
      </PagesNewHomeCommonAnimationBoxShowOneByOne>
    </div>
  </PagesNewHomeCommonContainer>
</template>

<script lang="ts" setup>
import { ResizeSensor } from "css-element-queries"
import { SCENE_ID, getSceneData } from "~/constant/pages/newHome"

const hoverAreaRef = ref()

const sceneData = getSceneData()

const tabIndex = ref(0)

const tabChange = (index: number) => {}

const getIndex = (index, i) => {
  let indexValue = 0
  for (let j = 0; j <= index; j++) {
    if (index === j) {
      indexValue += i
      break
    } else {
      indexValue += sceneData[j].effectList.length
    }
  }
  return indexValue
}

const showCompareImg = computed(() => {
  return (index, i) => {
    return tabIndex.value === index && watchBox.value[getIndex(index, i)]?.value === false
  }
})

const getHideClass = computed(() => {
  return (index, i) => {
    return !showCompareImg.value(index, i) && watchBox.value.some((e) => e.value === false)
  }
})

const getItemStyle = computed(() => {
  return (index: number, i: number) => {
    return {
      "--top": sceneData[index].effectList[i].top,
      "--left": sceneData[index].effectList[i].left,
      ...(() => {
        if (sceneData[index].effectList[i].brightness) {
          return {
            "--brightness": sceneData[index].effectList[i].brightness
          }
        }
        return {}
      })()
    }
  }
})

const compareAreaRef = ref()

const getImageWidth = () => {
  if (!compareAreaRef.value) {
    return 0
  }
  return compareAreaRef.value.offsetWidth
}

const getImageHeight = () => {
  if (!compareAreaRef.value) {
    return 0
  }
  return compareAreaRef.value.offsetHeight
}

const watchBox = ref([])

const setWatch = async () => {
  await nextTick()
  hoverAreaRef.value.forEach((element) => {
    const { isOutside } = useMouseInElement(element)
    watchBox.value.push(isOutside)
  })
}

const boxWidth = ref(0)
const boxHeight = ref(0)

onMounted(() => {
  // const body = document.getElementsByTagName("body")[0]
  // new ResizeSensor(body, () => {
  //   nextTick(() => {
  //     boxWidth.value = getImageWidth()
  //     boxHeight.value = getImageHeight()
  //   })
  // })
  new ResizeSensor(compareAreaRef.value, () => {
    nextTick(() => {
      boxWidth.value = getImageWidth()
      boxHeight.value = getImageHeight()
    })
  })
  setWatch()
})
</script>

<style lang="scss" scoped src="./index.scss" />

<style lang="scss" scoped>
.scene-page {
  .scene-container {
    .compare-area {
      --box-wdith: v-bind(boxWidth);
      --box-height: v-bind(boxHeight);
    }
  }
}
</style>
