<template>
  <div ref="enjoyPageRef" class="enjoy-page">
    <div class="bg-area">
      <CommonImage
        class="bg"
        :src="getCloudAssets('/images/pages/newHome/home-enjoy-bg.webp')"
        :style="bgStyle"
        :lazy="true"
        :alt="useTranslateI18n('newHome.enjoy.alt')"
        background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
      />
    </div>
    <PagesNewHomeCommonContainer class="enjoy-content">
      <PagesNewHomeCommonAnimationFadeinContainer class="box" :transform="30" :ani-show-top="200" :ani-hide-top="0">
        <div ref="contentRef" class="content-box">
          <h2
            class="title"
            v-html="useTranslateI18n('newHome.enjoy.title', [{ class: 'yellow-text', type: 'static' }])"
          />
          <PagesNewHomeCommonToDownloadBtn class="btn" @click="toDownload" />
        </div>
      </PagesNewHomeCommonAnimationFadeinContainer>
    </PagesNewHomeCommonContainer>
  </div>
</template>

<script lang="ts" setup>
import { ResizeSensor } from "css-element-queries"
import { StyleValue } from "vue"
import Arrow from "@/assets/images/pages/newHome/home-hero-btn-arrow.svg"
import { Breakpoints } from "~/constant/pages/newHome"

interface IProps {
  aniParams: {
    start: number
    end: number
  }
}

const props = withDefaults(defineProps<IProps>(), {
  aniParams: () => ({
    start: 0,
    end: 0
  })
})

const enjoyPageRef = ref()

const { y } = useWindowScroll()

const bgStyle = ref<StyleValue>()

let extra = 1000
let startScale = 0.695027
let endScale = 1
let startBorderRadius = 24

const setAnimation = (y: number) => {
  if (y < props.aniParams.start - extra || y > props.aniParams.end + extra) {
    return
  }
  let progress = 0
  if (y < props.aniParams.start) {
    progress = 0
  } else if (y > props.aniParams.end) {
    progress = 1
  } else {
    progress = (y - props.aniParams.start) / (props.aniParams.end - props.aniParams.start)
  }
  bgStyle.value = {
    transform: `scale(${startScale + progress * (endScale - startScale)})`,
    borderRadius: `${startBorderRadius * (1 - progress)}px`
  }
}

watchEffect(() => {
  if (props.aniParams.end !== 0 && props.aniParams.start !== 0 && y.value) {
    setAnimation(y.value)
  }
})

const breakPointList = useBreakpoints(Breakpoints) as any

const contentRef = ref()

const { _pointNewHomeEnjoyClick } = useNewHomeTsSdk()

const toDownload = () => {
  _pointNewHomeEnjoyClick()
}

const getResizeSensor = () => {
  nextTick(() => {
    if (breakPointList.$1920.value) {
      startScale = 1160 / window.innerWidth
      startBorderRadius = 32
    } else if (breakPointList.$1400.value) {
      startScale = 1160 / window.innerWidth
      startBorderRadius = 32
    } else if (breakPointList.$900.value) {
      startScale = 1
      startBorderRadius = 0
    } else if (breakPointList.$600.value) {
      startScale = 1
      startBorderRadius = 0
    } else {
      startScale = 1
      startBorderRadius = 0
    }
  })
}

onMounted(() => {
  const topDom = document.getElementsByTagName("body")[0]
  new ResizeSensor(topDom, () => {
    getResizeSensor()
  })
  getResizeSensor()
})
</script>

<style lang="scss" scoped src="./index.scss" />
