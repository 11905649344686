<template>
  <div class="scene-tab-choose">
    <div
      v-for="(item, index) in tabData"
      :key="index"
      :class="['tab-choose-item', { active: index === props.modelValue }]"
      @click="tabChange(index)"
    >
      <CommonImage
        class="img"
        :src="item.tabImg"
        :alt="item.alt"
        :lazy="true"
        background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
      />
      <div class="cover-area">
        <div class="cover-black" />
        <div class="cover-text-area">
          <div class="text-bg" />
          <div class="text-content">
            <div class="text-title">{{ item.tabTitle }}</div>
            <div class="text-desc">
              <div class="text-transition">
                {{ item.tabDesc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SceneType } from "~/types/pages/newHome"

interface propsInterface {
  tabData: Array<SceneType>
  modelValue: number
}

const props = withDefaults(defineProps<propsInterface>(), {
  tabData: () => {
    return [
      {
        tabTitle: "",
        tabDesc: ""
      }
    ]
  },
  modelValue: 0
})

const emits = defineEmits(["tabChange", "update:modelValue"])

const tabChange = (index: number) => {
  emits("tabChange", index)
  emits("update:modelValue", index)
}

onMounted(() => {})
</script>

<style lang="scss" scoped src="./index.scss" />
