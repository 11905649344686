<template>
  <div class="community-page">
    <div class="bg-area">
      <!-- <CommonImage
        class="bg"
        :src="getCloudAssets('/images/pages/newHome/home-community-bg.png')"
        :lazy="true"
        background="transparent"
      /> -->
    </div>
    <PagesNewHomeCommonContainer class="content-area">
      <PagesNewHomeCommonAnimationBoxShowOneByOne
        class="number-box"
        :transform="30"
        :ani-show-top="200"
        :ani-hide-top="0"
        @on-show="showNumber"
        @on-hide="hideNumber"
      >
        <div class="title-area delay-1">
          <h2 class="title1">{{ useTranslateI18n("newHome.community.title1") }}</h2>
        </div>

        <div class="num-area">
          <div class="card-list">
            <div
              v-for="(item, index) in communityData.cardList"
              :key="index"
              :class="['card-item', `delay-${index + 2}`]"
            >
              <div class="card-info">
                <div class="top-number">
                  <CommonCountTo ref="countToRef" :start-val="0" :end-val="item.value" />
                  <div v-if="item.valueUnit">{{ item.valueUnit }}</div>
                  <div class="icon">+</div>
                </div>
                <div class="card-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </PagesNewHomeCommonAnimationBoxShowOneByOne>
      <div class="users-area">
        <PagesNewHomeCommonAnimationBoxShowOneByOne :transform="30" :ani-show-top="200" :ani-hide-top="0">
          <div class="title-area delay-1">
            <h2 class="title3">{{ useTranslateI18n("newHome.community.title3") }}</h2>
          </div>

          <div class="users-scroll-area delay-2">
            <div ref="userListRef" class="users-list list-1">
              <PagesNewHomeUserCard v-for="(item, index) in getUserList" :key="index" :data="item" />
            </div>
            <div class="users-list list-2">
              <PagesNewHomeUserCard v-for="(item, index) in getUserList" :key="index" :data="item" />
            </div>
          </div>
        </PagesNewHomeCommonAnimationBoxShowOneByOne>
      </div>
      <div class="comming-area">
        <PagesNewHomeCommonAnimationBoxShowOneByOne :transform="30" :ani-show-top="200" :ani-hide-top="0">
          <div class="title-area delay-1">
            <h2 class="title4">{{ useTranslateI18n("newHome.community.title4") }}</h2>
            <div class="scroll-btn-area">
              <button :class="['btn', 'to-left', { 'btn-show': activityShowLeftBtn }]" @click="activityToLeft">
                <Arrow />
              </button>
              <button :class="['btn', 'to-right', { 'btn-show': activityShowRightBtn }]" @click="activityToRight">
                <Arrow />
              </button>
            </div>
          </div>

          <div ref="activityScrollOutRef" class="activity-area">
            <div ref="activityScrollInnerRef" class="activity-list">
              <PagesNewHomeCommingActivityCard
                v-for="(item, index) in getCommingActivityList"
                :key="index"
                :class="`delay-${index + 2}`"
                :data="item"
              />
            </div>
          </div>
        </PagesNewHomeCommonAnimationBoxShowOneByOne>
      </div>
    </PagesNewHomeCommonContainer>
  </div>
</template>

<script lang="ts" setup>
import { ResizeSensor } from "css-element-queries"
import { getCommunityData } from "~/constant/pages/newHome"
import Arrow from "@/assets/images/pages/newHome/home-simplify-life-arrow.svg"

import { EmitName } from "~/constant/eventBus"

const communityData = getCommunityData()

const getUserList = computed(() => {
  return communityData.usersSayList[useI18nCountry().value]
})

const getCommingActivityList = computed(() => {
  return communityData.comingList[useI18nCountry().value]
})

const speed = ref(50)

const aniTime = ref(0)

const userListRef = ref()

const getAnimationParam = () => {
  aniTime.value = userListRef.value?.offsetWidth / speed.value
}

const countToRef = ref()

const showNumber = () => {
  countToRef.value?.forEach(async (e) => {
    e.reset()
    await delay(200)
    e.start()
  })
}

const hideNumber = () => {}

const activityScrollOutRef = ref()
const activityScrollInnerRef = ref()
const { x: activityX } = useScroll(activityScrollOutRef, { behavior: "smooth" })

const activityShowLeftBtn = computed(() => {
  return activityX.value > 0
})

const activityShowRightBtn = computed(() => {
  return activityX.value + activityScrollOutRef.value?.offsetWidth < activityScrollInnerRef.value?.offsetWidth
})

const activityToLeft = () => {
  if (activityShowLeftBtn.value) {
    activityX.value -= activityScrollOutRef.value.offsetWidth / 2
  }
}

const activityToRight = () => {
  if (activityShowRightBtn.value) {
    activityX.value += activityScrollOutRef.value.offsetWidth / 2
  }
}

const getResizeSensor = () => {
  nextTick(() => {
    getAnimationParam()
  })
}

onMounted(() => {
  const body = document.getElementsByTagName("body")[0]
  new ResizeSensor(body, () => {
    getResizeSensor()
  })
})
</script>

<style lang="scss" scoped src="./index.scss" />
<style lang="scss" scoped>
.community-page {
  --ani-time: calc(v-bind(aniTime) * 1s);
}
</style>
