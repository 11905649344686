<template>
  <div class="advantage-tab-choose">
    <div class="top-area">
      <h2 class="title">
        {{ getTitle }}
      </h2>
      <div class="desc">{{ getDesc }}</div>
    </div>
    <div class="bottom-area">
      <!-- <div class="top-text">
        {{ getTopText }}
      </div> -->
      <PagesNewHomeCommonAnimationShowContainer class="num-area" :ani-show-top="150" :ani-hide-top="0" :use-if="true">
        <div class="num-text">
          <CommonCountTo ref="countToRef" :end-val="getNumber" :duration="300" :start-val="0" />
          <span>%</span>
        </div>
        <div class="icon-area">
          <CommonImage
            v-for="(item, index) in props.tabData"
            :key="index"
            :class="['up-icon', {'show-up': props.index === index}]"
            :src="getCloudAssets('/images/pages/newHome/home-advantage-up-icon.png')"
            :lazy="true"
            background="transparent"
          />
        </div>
      </PagesNewHomeCommonAnimationShowContainer>
      <div class="bottom-text">
        {{ getBottomText }}
      </div>
      <slot />
      <div class="tab-area">
        <div
          v-for="(item, index) in props.tabData"
          :key="index"
          :class="['tab-item', { active: props.index === index }]"
          @click="setActiveIndex(index)"
        >
          <div class="process" />
          <div class="content">
            {{ item.tabName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AdvantageType } from "~/types/pages/newHome"

interface propsInterface {
  tabData: Array<AdvantageType>
  index: number
  process: number
}

const props = withDefaults(defineProps<propsInterface>(), {
  tabData: () => {
    return [
      {
        tabName: "",
        desc: "",
        value: 0,
        video: "",
        topText: "",
        bottomText: "",
        alt: "",
        poster: ""
      }
    ]
  },
  process: 0
})

const emits = defineEmits(["tab-change", "update:index"])

const countToRef = ref()

const oldName = ref<string>("")
const oldDesc = ref<string>("")
const oldValue = ref<number>(0)
const oldTopText = ref<string>("")
const oldBottomText = ref<string>("")

const getTitle = computed(() => {
  if (!props.tabData[props.index]) {
    return oldName.value
  }
  oldName.value = props.tabData[props.index].tabName
  return props.tabData[props.index].tabName
})

const getDesc = computed(() => {
  if (!props.tabData[props.index]) {
    return oldDesc.value
  }
  oldDesc.value = props.tabData[props.index].desc
  return props.tabData[props.index].desc
})

const getTopText = computed(() => {
  if (!props.tabData[props.index]) {
    return oldTopText.value
  }
  oldTopText.value = props.tabData[props.index].topText
  return props.tabData[props.index].topText
})

const getBottomText = computed(() => {
  if (!props.tabData[props.index]) {
    return oldBottomText.value
  }
  oldBottomText.value = props.tabData[props.index].bottomText
  return props.tabData[props.index].bottomText
})

const getNumber = computed(() => {
  if (!props.tabData[props.index]) {
    setTimeout(() => {
      oldValue.value = 0
    }, 50)
    return oldValue.value
  }
  oldValue.value = props.tabData[props.index].value
  return props.tabData[props.index].value
})

// const valueDuring = 50
// const getStartValue = computed(() => {
//   if (!props.tabData[props.index]) {
//     return 1
//   }
//   const value = props.tabData[props.index].value
//   return value - valueDuring > 0 ? value - valueDuring : 1
// })

const setActiveIndex = (index: number) => {
  emits("tab-change", index)
}

defineExpose({
  countToRef: countToRef
})
</script>

<style lang="scss" src="./index.scss" scoped />
<style lang="scss" scoped>
.advantage-tab-choose {
  --process: v-bind(props.process);
}
</style>
