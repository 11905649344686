function bootstrap() {
  let lastTime = 0

  const vendors: string[] = ["webkit", "moz"]
  for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = (window as { [key: string]: any })[`${vendors[x]}RequestAnimationFrame`]
    window.cancelAnimationFrame =
      (window as { [key: string]: any })[vendors[x] + "CancelAnimationFrame"] ||
      (window as { [key: string]: any })[vendors[x] + "CancelRequestAnimationFrame"]
  }
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function (callback) {
      const currTime = new Date().getTime()
      const timeToCall = Math.max(0, 16 - (currTime - lastTime))
      const id = window.setTimeout(function () {
        callback.call(window, currTime + timeToCall)
      }, timeToCall)
      lastTime = currTime + timeToCall
      return id
    }
  }
  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function (id) {
      clearTimeout(id)
    }
  }
}
if (process.client) {
  bootstrap()
}

export function useRequestAnimationFrame() {
  const isClient = process.client
  return {
    request: isClient ? window?.requestAnimationFrame : new Function(),
    cancel: isClient ? window?.cancelAnimationFrame : new Function()
  }
}
